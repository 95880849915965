<template>
  <div class="live gray-bcg-color">
    <c-banner :bannerData="bannerData" :src="src"></c-banner>
    <c-menu :menuData="menuData"></c-menu>
    <a name="advantage" class="anchor-replace"></a>
    <div class="v-advantage">
      <c-title :titleArr="advantageTitle"></c-title>
      <product-advantage :advantageData="advantageData"></product-advantage>
    </div>
    <a name="solution" class="anchor-replace"></a>
    <div class="v-solution white_bcg-color">
      <c-title :titleArr="solutionTitle"></c-title>
      <c-solution :src="solutionSrc"></c-solution>
    </div>
    <a name="function" class="anchor-replace"></a>
    <div>
      <c-title :titleArr="functionTitle"></c-title>
      <product-fun
        :productFunData="productFunData"
        serviceType="live"
      ></product-fun>
    </div>
    <a name="price" class="anchor-replace"></a>
    <div>
      <c-title :titleArr="priceTitle"></c-title>
      <c-price :priceData="priceData"></c-price>
    </div>
    <a name="doc" class="anchor-replace"></a>
    <div class="document">
      <c-title
        class="doc"
        :titleArr="documentTitle"
        :isDocTitle="true"
      ></c-title>
      <div class="doc-content c-width">
        <a :href="`${docSrc}docs/show/2.html`" target="_blank">产品文档</a>
        <a :href="`${docSrc}docs/show/11.html`" target="_blank">快速开始</a>
        <a :href="`${docSrc}docs/show/73.html`" target="_blank">API</a>
        <a :href="`${docSrc}docs/show/173.html`" target="_blank">SDK</a>
        <a :href="`${docSrc}docs/show/176.html`" target="_blank">常见问题</a>
      </div>
    </div>
    <a name="recommand" class="anchor-replace"></a>
    <div>
      <c-title :titleArr="recommendTitle"></c-title>
      <c-recommend curService="live"></c-recommend>
    </div>
  </div>
</template>

<script>
import {
  cRecommend,
  cBanner,
  cPrice,
  cMenu,
  cSolution,
  productFun,
  productAdvantage
} from '../components'
import { cTitle } from '@/components'
export default {
  components: {
    cRecommend,
    cBanner,
    cPrice,
    cMenu,
    cSolution,
    productFun,
    productAdvantage,
    cTitle
  },
  data () {
    return {
      docSrc: window.urlConfig.docUrl,
      advantageTitle: ['产品优势'],
      functionTitle: ['产品功能'],
      solutionTitle: ['端到端的解决方案'],
      priceTitle: ['产品价格'],
      documentTitle: ['产品文档'],
      recommendTitle: ['相关推荐'],
      bannerData: {
        title: '直播',
        textData: [
          '提供从视频采集到视频播放的全流程直播服务',
          '快速部署搭建直播业务',
          '助力打造企业级直播平台'
        ],
        href2: `${window.urlConfig.docUrl}docs/show/166.html`
      },
      src: require('../../../assets/images/live/live-banner.png'),
      solutionSrc: require('../../../assets/images/live/solution@2x.png'),
      advantageData: [
        {
          src: require('../../../assets/images/live/advantage1@2x.png')
        },
        {
          src: require('../../../assets/images/live/advantage2@2x.png')
        },
        {
          src: require('../../../assets/images/live/advantage3@2x.png')
        },
        {
          src: require('../../../assets/images/live/advantage4@2x.png')
        }
      ],
      priceData: [
        {
          bcg: '#00EB82',
          title: '流量计费',
          text: '1.5元／GB',
          detailUrl: `${window.urlConfig.docUrl}docs/show/7.html#211-直播流量计费`
        },
        {
          bcg: '#65A4FE',
          title: '带宽计费',
          text: '1.5元／Mbps／天',
          detailUrl: `${window.urlConfig.docUrl}docs/show/7.html#212-直播带宽计费`
        },
        {
          bcg: '#EEAF56',
          title: '转码计费',
          text: '0.08元／分钟',
          detailUrl: `${window.urlConfig.docUrl}docs/show/7.html#222-直播转码费用`
        }
      ],
      menuData: [
        {
          src: require('../../../assets/images/subnav1@2x.png'),
          id: '#advantage',
          text: '产品优势'
        },
        {
          src: require('../../../assets/images/subnav2@2x.png'),
          id: '#solution',
          text: '解决方案'
        },
        {
          src: require('../../../assets/images/subnav3@2x.png'),
          id: '#function',
          text: '产品功能'
        },
        {
          src: require('../../../assets/images/subnav4@2x.png'),
          id: '#price',
          text: '产品价格'
        },
        {
          src: require('../../../assets/images/subnav5@2x.png'),
          id: '#doc',
          text: '产品文档'
        },
        {
          src: require('../../../assets/images/subnav6@2x.png'),
          id: '#recommand',
          text: '相关推荐'
        }
      ],
      productFunData: [
        {
          className: 'livePush',
          title: '直播推流',
          desc:
            'RTMP推流，多种推流方式接入，一键开启美颜，满足不同场景下的客户需求'
        },
        {
          className: 'livewatch',
          title: '直播观看',
          desc: '多终端播放SDK，全网加速，享受多平台下的极致观看体验'
        },
        {
          className: 'liverecord',
          title: '直播回看',
          desc:
            '实时收录直播内容，直播转点播，指定时段按需生成点播，录制方式灵活'
        },
        {
          className: 'livetranscode',
          title: '实时转码',
          desc:
            '多码率音视频转码、水印方案，满足不同终端设备的播放需求，保障复杂网络下的流畅直播体验'
        },
        {
          className: 'livejianhuang',
          title: '智能鉴黄',
          desc:
            '支持对涉黄行为进行高效自动识别，可根据用户管控尺度，灵活调整策略'
        },
        {
          className: 'livesecurity',
          title: '直播安全',
          desc:
            '支持多种鉴权方式，与业务结合灵活选择，极大提升直播服务安全性，支持全网HTTPS安全协议，提供企业级防护，充分保护资源不被盗链'
        },
        {
          className: 'livecount',
          title: '数据统计',
          desc:
            '提供实时推流质量监控、多维度访客数据，全方位的数据统计帮助用户及时定位问题并从多个维度了解观众'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.live {
  .v-advantage {
    padding-bottom: 40px;
  }
  .doc {
    padding-top: 44px;
  }
  .doc-content {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    margin-top: 53px;
    a {
      color: #bfcddd;
      font-size: 18px;
      text-align: center;
      line-height: 32px;
      font-weight: 500;

      &:not(:last-child) {
        border-right: 1px solid #fff;
      }

      &:hover {
        color: #fff;
      }
    }
  }
}
</style>
